import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from '../images/gembewatson-logo.svg'

const Header = ({ siteTitle, menuLinks }) => (
<header style={{ background: `#16161D`, marginBottom: `1.45rem`, padding: `1.45rem 0 0 0`, }} >
  <Link to="/" style={{ color: `white`, textDecoration: `none`, }} >
    <img style={{ marginBottom: `0rem`, marginRight: `-1.25rem`, maxWidth: `7rem`, display: `inline-block` , verticalAlign: `middle` , }} src={Logo} alt='Logo' />
    <h2 style={{ margin: 0 , display: `inline-block` , fontFamily: `Ovo` , }}>
      Gembe & Watson
    </h2>
  </Link>
  <nav style={{ display: `inline-block` , }} >
    <ul style={{ display: "flex", flex: 1 , }}>
      {menuLinks.map(link => (
        <li key={link.name} style={{ listStyleType: `none`, padding: `.5rem`, }} >
          <Link style={{ color: `white` }} to={link.link}>
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
</header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
